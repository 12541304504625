import React, { useState, useEffect } from "react";
import { IconButton, Dialog, DialogTitle, DialogContent, Typography, Divider, Button, Stack, Checkbox, FormControlLabel, FormGroup, TextField, FormControl } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { useSearchContext } from '../../context/searchContext';
import { label_bread, label_sweets, label_osaka, label_kyoto, label_hyogo, label_shiga, label_nara, label_wakayama, label_other } from "../../assets/label/label";

// 前回の検索条件に検索項目が含まれているかどうかを判定
function isSearchItem(searchString, key) {
    return (searchString.includes(key) || searchString.length === 0)
}

// ロードアイコン
function LoadingOverlay({ isLoading }) {
    if (!isLoading) return null;

    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 2,
            pointerEvents: "all"
        }}>
            <CircularProgress />
        </div>
    );
}

// 検索ダイアログタイトル
function SearchTitle({ onClose }) {
    return (
        <DialogTitle>
            お店情報検索
            <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                sx={{ position: "absolute", right: 24, top: 12 }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    )
}

// 検索ダイアログコンテンツ
function SearchContents({ handleSearch, onClose }) {
    const { searchKeyword, searchCategory, searchArea } = useSearchContext();
    // キーワードのステータス パイプをスペースに変換して追加
    const [keyword, setKeyword] = useState(searchKeyword.replace(/\|/g, " "));
    // カテゴリーのステータス
    const [categoryAll, setCategoryAll] = useState(searchCategory !== "");
    const [categoryBread, setCategoryBread] = useState(isSearchItem(searchCategory, label_bread));
    const [categorySweets, setCategorySweets] = useState(isSearchItem(searchCategory, label_sweets));
    // 地域のステータス
    const [areaAll, setAreaAll] = useState(searchArea !== "");
    const [areaOsaka, setAreaOsaka] = useState(isSearchItem(searchArea, label_osaka));
    const [areaKyoto, setAreaKyoto] = useState(isSearchItem(searchArea, label_kyoto));
    const [areaHyogo, setAreaHyogo] = useState(isSearchItem(searchArea, label_hyogo));
    const [areaShiga, setAreaShiga] = useState(isSearchItem(searchArea, label_shiga));
    const [areaNara, setAreaNara] = useState(isSearchItem(searchArea, label_nara));
    const [areaWakayama, setAreaWakayama] = useState(isSearchItem(searchArea, label_wakayama));
    const [areaOther, setAreaOther] = useState(isSearchItem(searchArea, label_other));

    // カテゴリー全選択の処理
    useEffect(() => {
        if (categoryBread || categorySweets) {
            setCategoryAll(true);
        } else {
            setCategoryAll(false);
        }
    }, [categoryBread, categorySweets]);

    // 地域全選択の処理
    useEffect(() => {
        if (areaOsaka || areaKyoto || areaHyogo || areaShiga || areaNara || areaWakayama || areaOther) {
            setAreaAll(true);
        } else {
            setAreaAll(false);
        }
    }, [areaOsaka, areaKyoto, areaHyogo, areaShiga, areaNara, areaWakayama, areaOther]);

    // キーワードリスト作成
    const makeKeywordList = () => {
        // キーワードに全角スペースまたは半角スペースが含まれている場合は、パイプ区切りに変換
        const searchKeyword = keyword.replace(/　/g, "|").replace(/ /g, "|");
        // 最初の文字、最後の文字がパイプの場合は削除
        return searchKeyword.replace(/^\||\|$/g, "");
    }

    // カテゴリーリスト作成
    const makeCategoryList = () => {
        let categoryList = [];
        let categoryString = label_bread + "|" + label_sweets;
        if (categoryBread) categoryList.push(label_bread);
        if (categorySweets) categoryList.push(label_sweets);
        if (categoryList.length !== 0) {
            categoryString = categoryList.join("|");
        }
        return categoryString;
    };
    // 地域選択の処理
    const makeAreaList = () => {
        let areaList = [];
        let areaString = label_osaka + "|" + label_kyoto + "|" + label_hyogo + "|" + label_shiga + "|" + label_nara + "|" + label_wakayama + "|" + label_other;
        if (areaOsaka) areaList.push(label_osaka);
        if (areaKyoto) areaList.push(label_kyoto);
        if (areaHyogo) areaList.push(label_hyogo);
        if (areaShiga) areaList.push(label_shiga);
        if (areaNara) areaList.push(label_nara);
        if (areaWakayama) areaList.push(label_wakayama);
        if (areaOther) areaList.push(label_other);
        if (areaList.length !== 0) {
            areaString = areaList.join("|");
        }
        return areaString;
    };

    return (
        <DialogContent dividers>
            <FormControl fullWidth margin="none">
                <Typography fontWeight="bold">キーワードから探す</Typography>
                <TextField
                    variant="outlined"
                    size="small"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                />
            </FormControl>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            {/* カテゴリー選択のグループ</FormGroup> */}
            <FormControlLabel
                control={<Checkbox checked={categoryAll}
                    onChange={() => {
                        setCategoryAll(!categoryAll);
                        setCategoryBread(!categoryAll);
                        setCategorySweets(!categoryAll);
                    }}
                />}
                label={<Typography fontWeight="bold">カテゴリー</Typography>}
            />
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={categoryBread} onChange={() => setCategoryBread(!categoryBread)} />}
                    label={label_bread}
                />
                <FormControlLabel
                    control={<Checkbox checked={categorySweets} onChange={() => setCategorySweets(!categorySweets)} />}
                    label={label_sweets}
                />
            </FormGroup>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            {/* 地域選択のグループ */}
            <FormControlLabel
                control={<Checkbox checked={areaAll}
                    onChange={() => {
                        setAreaAll(!areaAll);
                        setAreaOsaka(!areaAll);
                        setAreaKyoto(!areaAll);
                        setAreaHyogo(!areaAll);
                        setAreaShiga(!areaAll);
                        setAreaNara(!areaAll);
                        setAreaWakayama(!areaAll);
                        setAreaOther(!areaAll);
                    }}
                />}
                label={<Typography fontWeight="bold">エリア</Typography>}
            />
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={areaOsaka} onChange={() => setAreaOsaka(!areaOsaka)} />}
                    label={label_osaka}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaKyoto} onChange={() => setAreaKyoto(!areaKyoto)} />}
                    label={label_kyoto}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaHyogo} onChange={() => setAreaHyogo(!areaHyogo)} />}
                    label={label_hyogo}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaShiga} onChange={() => setAreaShiga(!areaShiga)} />}
                    label={label_shiga}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaNara} onChange={() => setAreaNara(!areaNara)} />}
                    label= {label_nara}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaWakayama} onChange={() => setAreaWakayama(!areaWakayama)} />}
                    label={label_wakayama}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaOther} onChange={() => setAreaOther(!areaOther)} />}
                    label={label_other}
                />
            </FormGroup>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Stack direction="row" spacing={5} sx={{ justifyContent: "center", mt: 2 }}>
                <Button variant="contained" size="large" color="primary" onClick={() => handleSearch(makeKeywordList(), makeCategoryList(), makeAreaList())} sx={{ width: 100 }} >検索</Button>
                <Button variant="contained" size="large" color="primary" onClick={onClose} sx={{ width: 100 }}>閉じる</Button>
            </Stack>
        </DialogContent>
    )
}

// メイン処理
function SearchScreen({ open, onClose }) {
    // コンテキストから検索関数を取得
    const { setSearchKeyword, setSearchCategory, setSearchArea } = useSearchContext();
    // 検索待ちステータス
    const [isLoading, setIsLoading] = useState(false);

    // 「検索」ボタンのイベントハンドラ
    const handleSearch = async (keyword, categoryList, areaList) => {
        // ローディング開始
        setIsLoading(true);
        // 検索条件を設定
        setSearchKeyword(keyword);
        setSearchCategory(categoryList);
        setSearchArea(areaList);
        // ローディング終了
        setIsLoading(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            {/* ローディングアイコン */}
            <LoadingOverlay isLoading={isLoading}/>
            {/* ダイアログのタイトル */}
            <SearchTitle onClose={onClose}/>
            {/* ダイアログの内容 */}
            <SearchContents handleSearch={handleSearch} onClose={onClose}/>
        </Dialog>
    );
}

export default SearchScreen;
