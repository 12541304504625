import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// application/jsonのヘッダー
const instanceJson = axios.create({
    baseURL: API_BASE_URL,
    timeout: 600000,
    headers: {
        "Content-Type": "application/json",
    }
});

// レスポンスインターセプター
instanceJson.interceptors.response.use(
    response => {
        let data = {};
        if("data" in response) {
            data = response.data
        }
        return data;
    },
    error => {
        return Promise.reject(error);
    }
);

// application/jsonのヘッダー
const instanceFormData = axios.create({
    baseURL: API_BASE_URL,
    timeout: 600000,
    headers: {
        "Content-Type": "multipart/form-data",
    }
});

// レスポンスインターセプター
instanceFormData.interceptors.response.use(
    response => {
        console.log(response);
        let data = {};
        if("data" in response) {
            data = response.data
        }
        return data;
    },
    error => {
        return Promise.reject(error);
    }
);


// GETリクエスト（通常）
const get = async (path, param={}) => {
    try {
        const data = await instanceJson.get(path, {"params": param});
        return data;
    } catch (error) {
        throw error;
    }
};

// POSTリクエスト（通常）
const set = async (path, data) => {
    try {
        const result = await instanceJson.post(path, JSON.stringify(data));
        return result;
    } catch (error) {
        throw error;
    }
};

// PUTリクエスト（通常）
const update = async (path, data) => {
    try {
        const result = await instanceJson.put(path, JSON.stringify(data));
        return result;
    } catch (error) {
        throw error;
    }
};

// DELETEリクエスト（通常）
const del = async (path) => {
    try {
        const result = await instanceJson.delete(path);
        return result;
    } catch (error) {
        throw error;
    }
};

// POSTリクエスト（通常）
const setFormData = async (path, data) => {
    try {
        const result = await instanceFormData.post(path, data);
        return result;
    } catch (error) {
        throw error;
    }
};

export { get, set, update, del, setFormData };
