import React, { useState } from "react";
import { IconButton, Dialog, DialogTitle, DialogContent, TextField, Divider, Button, Select, MenuItem, FormControl, InputLabel, Stack, Input } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { addStoreInfo } from "../../Services/StoreService"
import { convertToWebP } from "../common/image";
import { getCoordinates } from "../common/map";

// ロードアイコン
function LoadingOverlay({ isLoading }) {
    if (!isLoading) return null;

    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 2,
            pointerEvents: "all"
        }}>
            <CircularProgress />
        </div>
    );
}

// 追加ダイアログタイトル
function AddTitle({ onClose }) {
    return (
        <DialogTitle>
            お店情報入力
            <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                sx={{ position: "absolute", right: 24, top: 12 }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    )
}

// 追加ダイアログコンテンツ
function AddContents({ formData, onUpdate, handleAdd, onClose }) {
    // エリア情報
    // const areaList = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];
    const areaList = ["大阪府", "京都府", "兵庫県", "滋賀県", "奈良県", "和歌山県", "三重県", "その他"];

    // 必須項目が入力できているか確認
    const isFormValid = () => {
        let isUrl = true;
        if(formData.url) {
            const pattern = /^(https?:\/\/)/;
            isUrl = pattern.test(formData.url);    
        }
        return formData.name && formData.category && formData.product && formData.area && formData.address && isUrl;
    };
    
    // フィールド情報を保存
    const handleChange = (e) => {
        if (e.target.files) {
            const files = Array.from(e.target.files).slice(0, 5);
            onUpdate({ ...formData, [e.target.name]: files });
        } else {
            onUpdate({ ...formData, [e.target.name]: e.target.value });
        }
    };

    return (
        <DialogContent dividers>
            <TextField name="name" margin="dense" label="お店の名前" type="text" fullWidth required value={formData.name || ""} onChange={handleChange} />
            <FormControl fullWidth margin="dense">
                <InputLabel>カテゴリー</InputLabel>
                <Select
                    name="category"
                    labelId="category-label"
                    value={formData.category || ""}
                    label="カテゴリー"
                    onChange={handleChange}
                    required
                >
                    <MenuItem value="パン">パン</MenuItem>
                    <MenuItem value="スイーツ">スイーツ</MenuItem>
                </Select>
            </FormControl>
            <TextField name="product" margin="dense" label="おすすめ商品" type="text" fullWidth required value={formData.product || ""} onChange={handleChange} />
            <FormControl fullWidth margin="dense">
                <InputLabel>エリア</InputLabel>
                <Select
                    name="area"
                    labelId="area-label"
                    value={formData.area || ""}
                    label="エリア"
                    onChange={handleChange}
                    required
                >
                    {areaList.map((prefecture, index) => (
                        <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField name="address" margin="dense" label="住所" type="text" fullWidth required value={formData.address || ""} onChange={handleChange} />
            <TextField name="url" margin="dense" label="お店のURL" type="url" fullWidth value={formData.url || ""} onChange={handleChange} />
            <TextField name="comment" margin="dense" label="コメント" type="text" fullWidth multiline rows={4} value={formData.comment || ""} onChange={handleChange} />
            <FormControl fullWidth margin="dense">
                画像アップロード（最大5枚）
                <Input
                    name="images"
                    type="file"
                    inputProps={{ 
                        multiple: true,
                        accept: "image/*"
                    }}
                    onChange={handleChange}
                />
            </FormControl>                
            {formData.images && formData.images.map((image, index) => (
                <img 
                    key={index} 
                    src={URL.createObjectURL(image)} 
                    alt={`Preview ${index}`} 
                    style={{ maxWidth: "150px", maxHeight: "150px", margin: "5px" }}
                />
            ))}
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Stack direction="row" spacing={5} sx={{ justifyContent: "center", mt: 2 }}>
                <Button variant="contained" size="large" color="primary" onClick={handleAdd} sx={{ width: 100 }} disabled={!isFormValid()}>追加</Button>
                <Button variant="contained" size="large" color="primary" onClick={onClose} sx={{ width: 100 }}>閉じる</Button>
            </Stack>
        </DialogContent>
    )
}

// メイン処理
function AddScreen({ open, onClose, formData, onUpdate }) {
    // 追加待ちステータス
    const [isLoading, setIsLoading] = useState(false);

    // 「追加」ボタンのイベントハンドラ
    const handleAdd = async () => {
        // ローディング開始
        setIsLoading(true);

        const addData = new FormData();
        // 緯度経度を取得
        const {lat, lng} = await getCoordinates(formData.address);
        if((lat !== -1) || (lng !== -1)) {
            // フォームフィールドの値を追加
            addData.append("name", formData.name);
            addData.append("category", formData.category);
            addData.append("product", formData.product);
            addData.append("area", formData.area);
            addData.append("address", formData.address);
            addData.append("lat", String(lat));
            addData.append("lng", String(lng));
            if (!("url" in formData)) {
                formData.url = "";
            }
            addData.append("url", formData.url || "");
            if (!("comment" in formData)) {
                formData.comment = "";
            }
            addData.append("comment", formData.comment || "");
            // 画像ファイルを追加
            console.log(formData)
            if (formData.images) {
                for (const image of formData.images) {
                    const filename = image.name.split(".").slice(0, -1).join(".");
                    const webpImage = await convertToWebP(image);
                    addData.append("images", webpImage, filename +".webp");
                }
            }
            const result = await addStoreInfo(addData);
            if(result) {
                // 成功した場合
                alert("お店情報の登録に成功しました！")
                // // フォームデータを初期化
                // onUpdate({
                //     name: "",
                //     category: "",
                //     product: "",
                //     area: "",
                //     address: "",
                //     url: "",
                //     comment: "",
                //     images: []
                // });
                // // ダイアログを閉じる
                // onClose();
                // 画面をリロード
                window.location.reload()
            } else {
                // 失敗した場合
                alert("お店情報の登録に失敗しました。\nもう一度やり直してください。");
            }
        } else {
            // 緯度経度が取得できなかった場合
            alert("住所が認識できませんでした。\n内容を見直してください。");
        }

        // ローディング終了
        setIsLoading(false);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            {/* ローディングアイコン */}
            <LoadingOverlay isLoading={isLoading}/>
            {/* ダイアログのタイトル */}
            <AddTitle onClose={onClose}/>
            {/* ダイアログの内容 */}
            <AddContents formData={formData} onUpdate={onUpdate} handleAdd={handleAdd} onClose={onClose}/>
        </Dialog>
    );
}

export default AddScreen;
