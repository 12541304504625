import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import icon from "assets/images/icon.png";
import AddScreen from "../MainContent/AddScreen";
import SearchScreen from "../MainContent/SearchScreen";

function Header() {
    // ダイアログのオープン情報
    const [isAddDialogVisible, setAddDialogOpen] = useState(false);
    const [isSearchDialogVisible, setSearchDialogOpen] = useState(false);

    const handleAddDialogOpen = () => {
        setAddDialogOpen(true);
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };

    const handleSearchDialogOpen = () => {
        setSearchDialogOpen(true);
    };

    const handleSearchDialogClose = () => {
        setSearchDialogOpen(false);
    };

    // ダイアログが閉じられた時用の保持情報
    const [formData, setFormData] = useState({});

    const handleUpdate = (newData) => {
        setFormData({ ...formData, ...newData });
    };

    return (
        <AppBar position="static" component="header">
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="icon">
                    <Avatar alt="Remy Sharp" src={icon} />
                </IconButton>
                <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
                    関西パン＆スイーツマップ
                </Typography>
                <IconButton color="inherit" aria-label="search" onClick={handleSearchDialogOpen}>
                    <SearchIcon />
                </IconButton>
                <IconButton color="inherit" aria-label="add" onClick={handleAddDialogOpen}>
                    <AddIcon />
                </IconButton>
            </Toolbar>
            {isAddDialogVisible && (
                <AddScreen 
                    open={isAddDialogVisible} 
                    onClose={handleAddDialogClose} 
                    formData={formData} 
                    onUpdate={handleUpdate}
                />
            )}
            {isSearchDialogVisible && (
                <SearchScreen 
                    open={isSearchDialogVisible} 
                    onClose={handleSearchDialogClose} 
                />
            )}
        </AppBar>
    );
}

export default Header;
