import React from "react";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import MainContent from "./components/MainContent/MainContent.js";
import { LoadScript } from "@react-google-maps/api";
import { SearchProvider } from "./context/searchContext.js";

function App() {
    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}>
            <SearchProvider>
                <Header />
                <MainContent />
                <Footer />
            </SearchProvider>
        </LoadScript>
    );
}

export default App;
