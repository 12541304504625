import React, { createContext, useContext, useState } from 'react';
import { label_bread, label_sweets, label_osaka, label_kyoto, label_hyogo, label_shiga, label_nara, label_wakayama, label_other } from "../assets/label/label";

const SearchContext = createContext();
export const useSearchContext = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
	// 検索条件のステート
	const [searchKeyword, setSearchKeyword] = useState("");
	const [searchCategory, setSearchCategory] = useState(label_bread + "|" + label_sweets);
	const [searchArea, setSearchArea] = useState(label_osaka + "|" + label_kyoto + "|" + label_hyogo + "|" + label_shiga + "|" + label_nara + "|" + label_wakayama + "|" + label_other);

	return (
		<SearchContext.Provider 
			value = {{ 
				searchKeyword, setSearchKeyword,
				searchCategory, setSearchCategory,
				searchArea, setSearchArea
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};
