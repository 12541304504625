import { get, set, update, del, setFormData } from "./api";

// マップ情報取得
const getMapInfo = async (keyword="", category="", area="") => {
    let mapInfo = [];
    try {
        mapInfo = await get("map", {"keyword": keyword, "category": category, "area": area});
    } catch (error) {
        console.error("Fetching failed", error);
    }
    return mapInfo;
};


// 1ページに表示する情報を取得
const getPageInfo = async (keyword="", category="", area="", page=1, par=5) => {
    let pageInfo = [];
    try {
        pageInfo = await get("pageInfo", {"keyword": keyword, "category": category, "area": area, "page":page, "par": par});
    } catch (error) {
        console.error("Fetching failed", error);
    }
    
    return pageInfo;
};



// お店の詳細情報を取得
const getStoreDetail = async (id) => {
    let detailInfo = [];
    try {
        detailInfo = await get("storeDetail", {"id":id});
    } catch (error) {
        console.error("Fetching failed", error);
    }
    return detailInfo;
};


// 店舗情報登録
const addStoreInfo = async (addData) => {
    let result = false;
    const fetchData = async () => {
        try {
            const response = await setFormData("storeInfo", addData);
            result = true;
        } catch (error) {
            console.error("Fetching failed", error);
        }
    };
    await fetchData();
    return result;
};

export { getMapInfo, getPageInfo, getStoreDetail, addStoreInfo };