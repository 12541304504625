
// 緯度・経度を取得
export const getCoordinates = async (address) => {
    const geocoder = new window.google.maps.Geocoder();
    let lat = -1, lng = -1;

    try {
        const result = await new Promise((resolve, reject) => {
            geocoder.geocode({ address: address }, (response, status) => {
                if (status === "OK") {
                    resolve(response);
                } else {
                    reject("Geocode was not successful for the following reason: " + status);
                }
            });
        });
        lat = result[0].geometry.location.lat();
        lng = result[0].geometry.location.lng();
    } catch(error) {
        console.error(error);
    }
    return {lat, lng};
}
